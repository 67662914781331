<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important; width: 95vw; margin: 0 auto;">
        <div class="containers">
          <h3>Make Sale
            <a class="btn btn-default btn-sm" style="float: right;border: 1px solid #000;" href="/sales">Back to Sales</a>
          </h3>

          <div class="row">
            <div class="col-md-6">
                <h4 style="font-family: Roboto, sans-serif">Customer Details</h4>
                <label style="font-family: Roboto, sans-serif">Select Customer Option</label>
                <select v-model="customerOption" style="width: 98%; font-family:Roboto, sans-serif;" class="form-control">
                    <option value="NONE">No Customer Detail</option>
                    <option value="OLD">Select an Exisitng Customer</option>
                    <option value="NEW">Add a New Customer</option>
                </select>
                <div v-if="customerOption == 'OLD'">
                    <label style="font-family: Roboto, sans-serif" for="">Select Customer</label>
                    <select v-model="customerSelect" style="width: 98%;" v-if="customers" class="form-control">
                        <option v-for="(customer) in customers" v-bind:key="customer.id" :value="customer">{{ customer.custPin }} - {{ customer.custNm }}</option>
                    </select>
                </div>
                <div v-if="customerOption == 'NEW'">
                    <label style="font-family: Roboto, sans-serif" for="">Customer Pin</label>
                    <input style="font-family: Roboto, sans-serif" type="text" v-model="customerPin" class="form-control">
                    <label style="font-family: Roboto, sans-serif" for="">Customer Name</label>
                    <input style="font-family: Roboto, sans-serif" type="text" v-model="customerName" class="form-control">
                    <label style="font-family: Roboto, sans-serif" for="">Customer Email</label>
                    <input style="font-family: Roboto, sans-serif" type="text" v-model="customerEmail" class="form-control">
                    <label style="font-family: Roboto, sans-serif" for="">Customer Mobile</label>
                    <input style="font-family: Roboto, sans-serif" type="text" v-model="customerMobile" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <h4 style="font-family: Roboto, sans-serif">Sale Detail</h4>
                <label style="font-family: Roboto, sans-serif" for="">Mode of Payment</label>
                <select  v-model="modePayment" style="width: 98%; font-family: Roboto, sans-serif" v-if="codes[2]" class="form-control">
                    <option style="font-family: Roboto, sans-serif" v-for="(objItem) in codes[2].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                </select>
                <br>
                <label style="font-family: Roboto, sans-serif" for="">Trader Invoice Number <span style="font-family: Roboto; color: #8A8A8D; font-size: 13px;">(Your Invoice Number)</span></label>
                <input type="text" v-model="trdInvcNo" class="form-control">
                <div style="background-color: #f2f2f2;">
                    <table style="font-family: Roboto, sans-serif" class="table table-hover">
                        <tr>
                            <td>Sub Total</td>
                            <td>{{ formatToMoney(totTaxblAmt) }}</td>
                        </tr>
                        <tr>
                            <td>Vat</td>
                            <td>{{ formatToMoney(totTaxAmt) }}</td>
                        </tr>
                        <tr>
                            <td>Total Amount</td>
                            <td>{{ formatToMoney(totAmt) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
          </div>
          <hr>
          <p style="font-family: Roboto, sans-serif">Add Items to the sale</p>
          <div style="font-family: Roboto, sans-serif" class="row">
            <div style="font-family: Roboto, sans-serif" class="col-md-3">
                <label for="">Item Name</label>
                <select v-model="item" style="width: 98%;" v-if="items" class="form-control">
                    <option v-for="(objItem) in items" v-bind:key="objItem.id" :value="objItem">{{ objItem.itemNm }}</option>
                </select>
                <!-- <div style="height: 40px;" class="card flex justify-center">
                    <Select @filter="handleSearchProduct" filterMatchMode="startsWith" showClear style="text-align: center; vertical-align: middle; line-height: 20px;" v-model="selectedCountry" :options="items" filter optionLabel="name" placeholder="Select a Product" class="w-full md:w-56" :virtualScrollerOptions="{ itemSize: 38 }">
                        <template #value="slotProps">
                            <div v-if="slotProps.value" class="flex items-center">
                                <div style="font-size: 15px; padding: 5px 10px">{{ slotProps.value.itemNm }}</div>
                            </div>
                            <span v-else>
                                {{ slotProps.placeholder }}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div class="flex items-center">
                                <div style="font-size: 15px; padding: 5px 10px">{{ slotProps.option.itemNm }}</div>
                            </div>
                        </template>
                    </Select>
                </div> -->
                
            </div>
            <div style="font-family: Roboto, sans-serif" class="col-md-2">
                <label style="font-family: Roboto, sans-serif" for="">Item Price</label>
                <input type="number" min="1" class="form-control" v-model="itemPrice">
            </div>
            <div style="font-family: Roboto, sans-serif" class="col-md-2">
                <label style="font-family: Roboto, sans-serif" for="">Item Quantity</label>
                <input type="number" min="1" :max=itemQtyMax class="form-control" v-model="itemQty">
            </div>
            <div style="font-family: Roboto, sans-serif" class="col-md-2">
                <label style="font-family: Roboto, sans-serif" for="">Item Discount</label>
                <input type="number" min="0" class="form-control" v-model="itemDiscount">
            </div>
            <div style="font-family: Roboto, sans-serif" class="col-md-2">
                <label style="font-family: Roboto, sans-serif" for="">Sub Total (KES)</label>
                <input type="text" class="form-control" v-model="itemSubTotal">
            </div>
            <div style="font-family: Roboto, sans-serif" class="col-md-1">
                <button class="btn btn-sm btn-primary" v-if="itemQtyMax >= itemQty" style="margin-top:27px; font-family: Roboto, sans-serif" @click="addItem">Add</button>
                <button style="font-family: Roboto, sans-serif" v-else class="btn btn-sm btn-warning">Stock Not Available</button>
            </div>
          </div>
          <hr>
          <table class="table table-bordered" v-if="itemList.length > 0">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody v-if="itemList">
                <tr v-for="(itemL,index) in itemList" v-bind:key="itemL.itemId">
                    <td>{{ index+1 }}</td>
                    <td>{{ itemL.itemNm }}</td>
                    <td>{{ itemL.qty }}</td>
                    <td>KES {{ formatToMoney(itemL.prc) }}</td>
                    <td>KES {{ formatToMoney(itemL.dcAmt) }}</td>
                    <td>KES {{ formatToMoney(itemL.totAmt) }}</td>
                </tr>
            </tbody>
            <p v-else>No Items added to the sale yet</p>
        </table>
        </div>
      </section>
      <button v-if="itemList.length > 0" style="float: right;margin: 20px;" class="btn btn-warning" @click="clearItems">Clear Items</button>
      <button class="btn-style" style="float: right;margin: 20px;" @click="makeSale" v-if="clickableComplete">Complete the Sale</button>
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
import { toRaw } from 'vue';
import { ref } from "vue";
// import Select from "primevue/dropdown";


export default {
name: 'SalePage',
components: {
    Header,
    // Select
},


data(){
    return{
        selectedCountry: ref(),
        countries: [
            { name: 'Australia', code: 'AU' },
            { name: 'Brazil', code: 'BR' },
            { name: 'China', code: 'CN' },
            { name: 'Egypt', code: 'EG' },
            { name: 'France', code: 'FR' },
            { name: 'Germany', code: 'DE' },
            { name: 'India', code: 'IN' },
            { name: 'Japan', code: 'JP' },
            { name: 'Spain', code: 'ES' },
            { name: 'United States', code: 'US' }
        ],
        token: JSON.parse(localStorage.getItem('access_token')),
        customers: [],
        customerOption: "NONE",
        customerSelect:{},
        clickableComplete: false,
        customerId: "",
        codes:[],
        items: [],
        itemList: [],
        subTotal: 0,
        vat: 0,
        total: 0,
        customerPin:"",
        customerName:"",
        customerEmail:"",
        customerMobile:"",
        modePayment:"",
        remark:"",
        item:{},
        itemPrice:"0",
        itemQty: 1,
        itemQtyMax: 1,
        itemDiscount:"0",
        itemSubTotal: "0",
        taxblAmtA: 0.00,
        taxblAmtB: 0.00,
        taxblAmtC: 0.00,
        taxblAmtD: 0.00,
        taxblAmtE: 0.00,
        taxRtA: 0,
        taxRtB: 16,
        taxRtC: 0,
        taxRtD: 0,
        taxRtE: 8,
        taxAmtA: 0,
        taxAmtB: 0,
        taxAmtC: 0,
        taxAmtD: 0,
        taxAmtE: 0,
        totTaxblAmt: 0.00,
        totTaxAmt: 0.00,
        totAmt: 0.00,

    }
},
methods:{
    handleSearchProduct(originalEvent, value) {
        console.log(originalEvent)
        this.items = this.items.filter(item => item.itemNm.startsWith(value))
    },
    clearItems(){
        this.itemList = [];
        this.taxblAmtA = 0.00
        this.taxblAmtB = 0.00
        this.taxblAmtC = 0.00
        this.taxblAmtD = 0.00
        this.taxblAmtE = 0.00
        this.taxAmtA = 0
        this.taxAmtB = 0
        this.taxAmtC = 0
        this.taxAmtD = 0
        this.taxAmtE = 0
        this.totTaxblAmt = 0.00
        this.totTaxAmt = 0.00
        this.totAmt = 0.00
    },
    addItem(){
        this.clickableComplete = true
        if(this.itemPrice !== "KES 0"){
            const itemSeq = this.itemList.length + 1
            var dcAmt = 0
            var dcRate = 0
            if(this.itemDiscount != "0"){
                dcAmt = this.itemDiscount
                dcRate = this.roundToTwo(((this.itemPrice - (this.itemPrice - dcAmt))/this.itemPrice)*100)
            }
            var taxitemAmt = 0
            var taxblAmt = 0
            if(this.item.taxTyCd == "B"){
                taxblAmt = this.roundToTwo(this.itemSubTotal/1.16)
                taxitemAmt = this.roundToTwo(this.itemSubTotal - taxblAmt)
                this.taxblAmtB = this.roundToTwo(this.taxblAmtB+ taxblAmt)
                this.taxAmtB = this.roundToTwo(this.taxAmtB+ taxitemAmt)
            } else if(this.item.taxTyCd == "E"){
                taxblAmt = this.roundToTwo(this.itemSubTotal/1.08)
                taxitemAmt = this.roundToTwo(this.itemSubTotal - taxblAmt)
                this.taxblAmtE = this.roundToTwo(this.taxblAmtE+ taxblAmt)
                this.taxAmtE = this.roundToTwo(this.taxAmtE+ taxitemAmt)
            } else {
                taxblAmt = this.itemSubTotal
                taxitemAmt = 0
                if(this.item.taxTyCd == "A"){
                    this.taxblAmtA = this.roundToTwo(this.taxblAmtA + this.itemSubTotal)
                } else if(this.item.taxTyCd == "C") {
                    this.taxblAmtC = this.roundToTwo(this.taxblAmtC + this.itemSubTotal)
                } else if(this.item.taxTyCd == "D") {
                    this.taxblAmtD = this.roundToTwo(this.taxblAmtD + this.itemSubTotal)
                }
            }

            this.totTaxblAmt = this.roundToTwo(parseFloat(this.totTaxblAmt) + parseFloat(taxblAmt))
            this.totTaxAmt = this.roundToTwo(parseFloat(this.totTaxAmt) + parseFloat(taxitemAmt))
            this.totAmt = this.roundToTwo(parseFloat(this.totAmt) + parseFloat(this.itemSubTotal))
            const obj={
                "itemSeq": itemSeq,
                "itemId":this.item.id,
                "itemCd": this.item.itemCd,
                "itemClsCd": this.item.itemClsCd,
                "itemNm": this.item.itemNm,
                "itemNmDef": this.item.itemCdDf,
                "bcd": this.item.bcd,
                "pkgUnitCd": this.item.pkgUnitCd,
                "pkg": this.itemQty,
                "qtyUnitCd": this.item.qtyUnitCd,
                "qty": this.itemQty,
                "prc": parseFloat(this.itemPrice),
                "splyAmt": this.roundToTwo(this.itemPrice * this.itemQty),
                "dcRt": dcRate,
                "dcAmt": dcAmt,
                "taxTyCd": this.item.taxTyCd,
                "taxblAmt": taxblAmt,
                "taxAmt": taxitemAmt,
                "totAmt": this.itemSubTotal
            }
            this.itemPrice = "KES 0"
            this.itemQty = 0
            this.itemList.push(obj)
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Item',
                text: "Select a new Item to add to the sale"
            })
        }
    },
    async makeSale () {
        this.clickableComplete = false
        const dateTimeNow = this.getCurrentDateTimeFormatted()
        const dateOnlyNow= this.getCurrentDateFormatted()
        let configDataOptions = JSON.stringify({ 
            "tin": "P000000003C",
            "bhfId": "00",
            "trdInvcNo": this.trdInvcNo,
            "invcNo": "1",
            "orgInvcNo": 0,
            "custId":this.customerId,
            "custType":this.customerOption,
            "custTin": this.customerPin,
            "custNm": this.customerName,
            "custEmail": this.customerEmail,
            "custTel": this.customerMobile,
            "salesTyCd": "N",
            "rcptTyCd": "S",
            "pmtTyCd": this.modePayment,
            "salesSttsCd": "02",
            "cfmDt": dateTimeNow,
            "salesDt": dateOnlyNow,
            "stockRlsDt": dateTimeNow,
            "totItemCnt": this.itemList.length,
            "taxblAmtA": this.taxblAmtA,
            "taxblAmtB": this.taxblAmtB,
            "taxblAmtC": this.taxblAmtC,
            "taxblAmtD": this.taxblAmtD,
            "taxblAmtE": this.taxblAmtE,
            "taxRtA": 0,
            "taxRtB": 16,
            "taxRtC": 0,
            "taxRtD": 0,
            "taxRtE": 8,
            "taxAmtA": this.taxAmtA,
            "taxAmtB": this.taxAmtB,
            "taxAmtC": this.taxAmtC,
            "taxAmtD": this.taxAmtD,
            "taxAmtE": this.taxAmtE,
            "totTaxblAmt": this.totTaxblAmt,
            "totTaxAmt": this.totTaxAmt,
            "totAmt": this.totAmt,
            "prchrAcptcYn": "N",
            "remark": "Sale",
            "regrId": "Admin",
            "regrNm": "Admin",
            "modrId": "Admin",
            "modrNm": "Admin",
            receipt: {
                "custTin": this.customerPin,
                "custMblNo": this.customerMobile,
                "custEmail": this.customerEmail,
                "rptNo": 1,
                "rcptPbctDt": dateTimeNow,
                "trdeNm": "",
                "adrs": this.customerName,
                "topMsg": "Sale of VSCU",
                "btmMsg": "Bottom Sale of VSCU",
                "prchrAcptcYn": "N"
            },
            itemList: this.itemList
        })
        
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: configDataOptions
        };
        const res = await fetch(process.env.VUE_APP_URL+'/sales', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Sale made successfully',
                text: data.message
            })
            console.log(data)
            this.$router.push("/sales")
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Sale not found',
                text: data.message
            })
            this.clickableComplete = true
        }
    },
    async getCustomers () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/customers', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.customers = data.customers
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Customers not found',
                text: data.message
            })
        }
    },
    async getCodes () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items/pullcodes', requestOptions)
        const data = await res.json()
        this.codes = data.kraResult.clsList;
    },
    async getItems () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.items = data.items
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not found',
                text: data.message
            })
        }
    },
    roundToTwo(num) {
        try{
            return parseFloat(num.toFixed(2));
        } catch(error) {
            return num;
        }
    },
    getCurrentDateFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');

      return `${year}${month}${day}`;
    },
    getCurrentDateTimeFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    formatToMoney(value) {
      const formattedValue = Number(value).toFixed(2);
      return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
},
mounted(){
    this.getCustomers()
    this.getCodes()
    this.getItems()
},
watch:{
    item(newValue, oldValue){
        console.log(newValue.itemCd)
        console.log(oldValue.itemCd)
        this.itemPrice = newValue.dftPrc
        this.itemQty = 1
        if(newValue.itemTyCd == "3"){
            this.itemQtyMax = 1
        } else {
            this.itemQtyMax = newValue.currentStock
        }
        this.itemSubTotal =newValue.dftPrc;
    },
    itemQty(newValue, oldValue){
        console.log(oldValue)
        this.itemSubTotal =this.roundToTwo((this.itemPrice * newValue) - this.itemDiscount);
    },
    itemDiscount(newValue, oldValue){
        console.log(oldValue)
        this.itemSubTotal = this.roundToTwo((this.itemPrice * this.itemQty) - newValue)
    },
    customerSelect(newValue, oldValue){
        console.log(oldValue)
        let customerO = toRaw(newValue)
        this.customerEmail = customerO.email
        this.customerId = customerO.id
        this.customerMobile = customerO.telNo
        this.customerName = customerO.custNm
        this.customerPin = customerO.custPin
    }
}
}
</script>
<style>
.p-dropdown-items li {
    display: block;
    line-height: 20px;
    margin: 0;
    padding: 5px;
    font-family: Roboto;
}

.p-dropdown {
    padding: 0;
}

.p-dropdown-label {
    padding: 2px;
    line-height: 40px;
}
</style>